<template>
  <div class="layout_common">
    <div class="main_box">
      <div class="main_box_con">
        <searchHead
          compType="store-unpack"
          :storeInfo="storeInfo"
          @search="search"
          @reset="search"
          @rankClick="rankClick"
          @saleArea="saleArea"
        />
        <tableComp
          v-loading="loading"
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
          <el-table-column slot="deliveryCount" :label="tableHeadLabel1">
            <template v-slot="scope">
              {{ scope.row.deliveryCount}}
            </template>
          </el-table-column>
          <el-table-column slot="packCount" :label="tableHeadLabel2">
            <template v-slot="scope">
              {{ scope.row.packCount}}
            </template>
          </el-table-column>
          <el-table-column slot="rate" :label="tableHeadLabel3">
            <template v-slot="scope">
              {{ scope.row.rate + '%' }}
            </template>
          </el-table-column>
          <el-table-column
            slot="operate"
            label="开箱不良问题及原因"
            fixed="right"
            width="200"
          >
            <template v-slot="scope">
              <btnComp
                btnType="text"
                @click="goDetail(scope.row, 'store-unpack-analy')"
                >问题分析</btnComp
              >
              <btnComp
                btnType="text"
                @click="goDetail(scope.row, 'store-unpack-sku')"
                >sku统计</btnComp
              >
              <btnComp
                btnType="text"
                @click="goDetail(scope.row, 'store-unpack-detail')"
                >发货明细</btnComp
              >
            </template>
          </el-table-column>
        </tableComp>
      </div>
    </div>
    <!-- 商家发货排名 -->
    <rank :isShow="isShow" @close="close" @selectStore="selectStore" />
  </div>
</template>
<script>
import searchHead from '@/views/data-center/components/searchHead'
import rank from '@/views/data-center/components/rank'
import { formatDate, diffDate } from '@/utils/common'
import { unpackBadStat } from '@/api'
export default {
  components: {
    searchHead,
    rank
  },
  data () {
    return {
      curPage: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      isShow: false,
      storeInfo: {},
      storeId: '',
      storeName: '',
      beginMonth: '',
      endMonth: '',
      startTime: '',
      endTime: '',
      type: 1,
      timeType: 3,
      orderType: 1,
      level: '',
      cityId: '',
      tableData: [],
      tableHeadLabel1: '发货数量',
      tableHeadLabel2: '发货开箱不良',
      tableHeadLabel3: '发货不良率',
      theadName: [
        { slot: 'rate' },
        { prop: 'storeName', label: '商家' },
        { slot: 'deliveryCount' },
        { slot: 'packCount' },
        { slot: 'operate' }

        // { prop: 'deliveryCount', label: '发货数量' },
        // { prop: 'packCount', label: '开箱不良数量' },
        // { slot: 'operate' }
      ]
    }
  },
  mounted () {
    /* // 缺省默认6个月
    this.endTime = formatDate(new Date()).slice(0, 7)
    this.startTime = diffDate(-5, 'months', true).slice(0, 7)
    this.getList() */
  },
  watch: {
    // 监听type   动态表头
    type (val) {
      switch (val) {
        case 1:
          this.tableHeadLabel1 = '发货数量'
          this.tableHeadLabel2 = '发货开箱不良'
          this.tableHeadLabel3 = '发货开箱不良率'
          break
        case 2:
          this.tableHeadLabel1 = '客户数量'
          this.tableHeadLabel2 = '客户开箱不良'
          this.tableHeadLabel3 = '客户开箱不良率'
          break
        case 3:
          this.tableHeadLabel1 = '订单数量'
          this.tableHeadLabel2 = '订单开箱不良'
          this.tableHeadLabel3 = '订单开箱不良率'
          break
      }
    }
  },
  methods: {
    // 子组件传过来战区信息
    saleArea (salesArea, level, cityId) {
      this.level = level
      this.cityId = cityId
      this.endTime = formatDate(new Date()).slice(0, 7)
      this.startTime = diffDate(-5, 'months', true).slice(0, 7)
      this.getList()
    },
    getList () {
      const params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        level: this.level,
        cityId: this.cityId,
        type: this.type,
        timeType: this.timeType,
        orderType: this.orderType,
        storeId: this.storeId,
        startTime: this.startTime,
        endTime: this.endTime
      }
      this.loading = true
      unpackBadStat(params).then(res => {
        this.loading = false
        const { list, totalCount } = res.data
        this.tableData = Object.freeze(list)
        this.total = Object.freeze(totalCount)
      })
    },
    // 点击发货量排名
    rankClick (date) {
      this.isShow = true
      // 累计发货量默认是传空， 月度 默认是当前月
      this.curDate = date
    },
    // 选择的商家
    selectStore (val) {
      this.storeId = val.storeId
      this.storeName = val.storeName
      this.storeInfo = {
        storeId: this.storeId,
        storeName: this.storeName
      }
    },
    goDetail (row, name) {
      const { storeName, storeId } = row
      const route = this.$router.resolve({
        name,
        query: {
          storeName,
          storeId,
          type: this.type,
          orderType: this.orderType,
          timeType: this.timeType,
          startTime: this.startTime,
          endTime: this.endTime
        }
      })
      // 重新打开新的页面
      window.open(route.href, '_blank')
    },
    close () {
      this.isShow = false
    },
    search (val) {
      this.curPage = 1
      const {
        level,
        cityId,
        type,
        timeType,
        orderType,
        storeId,
        startTime,
        endTime
      } = val
      this.level = level
      this.cityId = cityId
      this.type = type
      this.timeType = timeType
      this.orderType = orderType
      this.storeId = storeId
      this.startTime = startTime
      this.endTime = endTime
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
<style lang="scss" scoped></style>
